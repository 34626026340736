import React, {useState} from 'react';
import '../App.css';
import {Box, Button, CircularProgress} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {useSdk} from "../sdk/SdkContext";

interface AddExerciseViewProps {
    lessonId: string;
}

const AddExercises: React.FC<AddExerciseViewProps> = (props) => {
    const {lessonId} = props
    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const sdk = useSdk();

    const onClick = () => {
        setIsGenerating(true)
        sdk.generateExercises(lessonId, {
            name: 'My Exercise',
            complexity: 'intermediate'
        })
            .then(() => {
                setIsGenerating(false)
            })
            .catch(e => {
                console.error(e)
                setIsGenerating(false)
            })
    };

    return (
        <Card variant="outlined" style={{margin: '20px', textAlign: 'left'}}>
            <CardContent>
                <Typography color="textSecondary">
                    Generate a list of exercises
                </Typography>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '5vh'
                }}>
                    <Button
                        onClick={onClick}
                        disabled={isGenerating}
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon/>}
                        endIcon={isGenerating ? <CircularProgress size={20} color="inherit"/> : null}
                    >
                        Add exercises
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
}

export default AddExercises;