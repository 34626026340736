import React, {createContext, useCallback, useContext, useMemo, useState} from 'react';
import {Sdk} from "@ancalled/grammarhub/dist";

interface SdkContextType {
    sdk: Sdk;
    onReady: (cb: () => void) => void
}

const SdkContext = createContext<SdkContextType | undefined>(undefined);

const nowEpoch = () => {
    return Math.floor(new Date().getTime() / 1000)
}

export const SdkProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [token, setToken] = useState<string | null>(localStorage.getItem('token'));
    const [expiry, setExpiry] = useState<number>(parseInt(localStorage.getItem('expiry') || '0'));
    const [refreshToken, setRefreshToken] = useState<string | null>(localStorage.getItem('refreshToken'));
    const [refreshExpiry, setRefreshExpiry] = useState<number>(parseInt(localStorage.getItem('refreshExpiry') || '0'));
    const [role, setRole] = useState<string | null>(localStorage.getItem('role'));

    const readyCallbacks = useMemo<Array<() => void>>(() => new Array<() => void>(), []);

    const onReady = useCallback((callback: () => void) => {
        readyCallbacks.push(callback);
    }, [readyCallbacks]);

    const sdk = useMemo(() => {
        const url = process.env.REACT_APP_BASE_URL || 'localhost';
        const httpPort = parseInt(process.env.REACT_APP_HTTP_PORT || '3000');
        return new Sdk(url, httpPort)
    }, []);


    const saveToken = (token: string, expiry: number, refreshToken: string, refreshExpiry: number, role: string) => {
        setToken(token)
        setExpiry(expiry)
        setRefreshToken(refreshToken)
        setRefreshExpiry(refreshExpiry)
        setRole(role)
        localStorage.setItem('token', token);
        localStorage.setItem('expiry', `${expiry}`);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('refreshExpiry', `${refreshExpiry}`);
        localStorage.setItem('role', role);
    }

    const updateToken = (token: string, expiry: number) => {
        setToken(token)
        setExpiry(expiry)
        localStorage.setItem('token', token);
        localStorage.setItem('expiry', `${expiry}`);
    }

    const silentGuestLogin = async () => {
        const {token, expiry, refreshToken, refreshExpiry} = await sdk.guestLogin()
        saveToken(token, expiry, refreshToken, refreshExpiry, 'guest')
    }

    const login = () => {
        console.log('Redirecting to login page')
        // TODO: implement classic login
    }

    const start = async () => {
        if (token) {
            console.log('Token is found')
            const now = nowEpoch();
            if (expiry < now) {
                console.log('Token is expired')
                if (refreshToken && refreshExpiry < now) {
                    console.log('Refreshing token')
                    const {token, expiry} = await sdk.renewToken(refreshToken)
                    updateToken(token, expiry)
                } else {
                    console.log('Refresh token either not found or expired')
                    if (!role || role === 'guest') {
                        console.log('Silent guest login')
                        await silentGuestLogin()
                    } else {
                        console.log('Regular login')
                        login()
                    }
                }
            } else {
                console.log(`Token is valid for the next ${Math.floor((expiry - nowEpoch()) / 60)} minutes`)
            }
        } else {
            if (!role || role === 'guest') {
                await silentGuestLogin()
            } else {
                login()
            }
        }
        if (token) {
            await sdk.start(token)
        }

    }

    if (!sdk.isConnected()) {
        start().then(() => {
            console.log('SDKContext: sdk logged in')
            readyCallbacks.forEach(cb => cb())
        }).catch(e => {
            console.error(`Failed to login: ${e}`)
        })
    }

    return <SdkContext.Provider value={{sdk, onReady}}>{children}</SdkContext.Provider>;
};

export const useSdkContext = () => {
    const context = useContext(SdkContext);
    if (context === undefined) {
        throw new Error('useWebSocket must be used within a SdkProvider');
    }
    return context;
};

export const useSdk = () => {
    const context = useContext(SdkContext);
    if (context === undefined) {
        throw new Error('useWebSocket must be used within a SdkProvider');
    }
    return context.sdk;
};
