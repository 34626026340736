import React, {useEffect, useState} from 'react';
import './App.css';
import {useSdkContext} from "./sdk/SdkContext";
import LessonView from "./lesson/LessonView";
import {AppBar, Box, Button, CircularProgress, IconButton, Menu, MenuItem, Toolbar} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import Typography from "@mui/material/Typography";
import {Lesson, applyLessonModification} from "@ancalled/grammarhub/dist";
import {initGA, logPageView} from "./analytics";
import {useLocation} from "react-router-dom";


const App: React.FC = () => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isReady, setIsReady] = useState(false)
    const [lessons, setLessons] = useState<Lesson[]>([]);
    const [isLessonCreating, setIsLessonCreating] = useState<boolean>(false);
    const [activeLessonId, setActiveLessonId] = useState<string>('');
    const open = Boolean(anchorEl);

    const usePageViews = () => {
        const location = useLocation();
        useEffect(() => {
            logPageView();
        }, [location]);
    }

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const {sdk, onReady} = useSdkContext()

    useEffect(() => {
        initGA();

        onReady(async () => {
            setIsReady(true)
            setLessons(await sdk.getLessons())
        })

        sdk.setOnLessonUpdate((upd) => {
            console.log(`Got lesson update: ${upd.lessonId}`)
            setLessons(applyLessonModification(lessons, upd))
        })
    }, [sdk, onReady, setIsReady, activeLessonId, lessons, setLessons])

    const onNewLessonClick = async () => {
        setIsLessonCreating(true)
        console.log('Creating new lesson')
        try {
            const lessonId = await sdk.createLesson();
            console.log(`Lesson created: ${lessonId}`)
            await sdk.subscribeOnLesson(lessonId);
            console.log('Subscribed on lesson');
            setActiveLessonId(lessonId)
        } catch (e) {
            console.error(e)
        }
        setIsLessonCreating(false);
    }

    usePageViews();

    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        sx={{mr: 2}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                        GrammarHub
                    </Typography>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose}>Lesson</MenuItem>
                        <MenuItem onClick={handleClose}>My account</MenuItem>
                        <MenuItem onClick={handleClose}>Logout</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            {/* Main content could go here */}
            <Typography variant="body1" component="div">
                {lessons.map(lesson => (
                    <LessonView key={lesson.id} lesson={lesson}/>
                ))}
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '5vh'
                }}>
                    <Button
                        onClick={onNewLessonClick}
                        disabled={!isReady}
                        variant="contained"
                        color="primary"
                        endIcon={isLessonCreating ? <CircularProgress size={20} color="inherit"/> : null}
                    >
                        New Lesson
                    </Button>
                </Box>
            </Typography>
        </div>
    );
}

export default App;
