import React, {useState} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {Box, IconButton, List, ListItem, TextField} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {Exercise} from "@ancalled/grammarhub/dist";

interface ExerciseViewProps {
    exercise: Exercise;
}

type TextPart = { type: 'text', value: string };
type InputPart = { type: 'input', index: number };
type Part = TextPart | InputPart

const placeholderRegex = /(__+\w*__)/;

const parseText = (text: string): Part[] => {
    const parts = text.split(placeholderRegex).filter(part => part !== '');
    if (parts.length === 1) {
        return [{type: 'text', value: text}, {type: 'input', index: 0}]
    }
    let idx = -1;
    return parts.map((part) => {
        if (placeholderRegex.test(part)) {
            idx += 1;
            return {type: 'input', index: idx}
        } else {
            return {type: 'text', value: part}
        }
    }) as Part[]
}

const ExerciseView: React.FC<ExerciseViewProps> = (props) => {
    const {exercise} = props
    const [userInputs, setUserInputs] = useState<string[]>([]);
    const [verificationStatus, setVerificationStatus] = useState<(boolean | null)[]>([]);

    const handleInputChange = (index: number, value: string) => {
        const updatedInputs = [...userInputs];
        updatedInputs[index] = value;
        setUserInputs(updatedInputs);
        setVerificationStatus([])
    };

    const verifyAnswers = () => {
        switch (exercise.answer.type) {
            case 'explicit': {
                const parts = parseText(exercise.body)
                const answers = parts
                    .filter(p => p.type === 'input')
                    .map(p => p.type === 'input' ? userInputs[p.index] : '')

                const correctAnswers: boolean[] = [];
                for (let i = 0; i < answers.length; i++) {
                    correctAnswers[i] = answers[i].trim().toLowerCase() === exercise.answer.answers[i].trim().toLowerCase();
                }
                setVerificationStatus(correctAnswers)
                break
            }
            case "implicit":

                break
        }
    }

    const renderExercise = (exercise: Exercise): React.ReactElement | React.ReactElement[] => {
        switch (exercise.answer.type) {
            case 'explicit': {
                const parts = parseText(exercise.body)
                return parts.map((part, idx) => {
                    if (part.type === 'input') {
                        return <TextField key={'inp-' + part.index}
                                          onChange={(e) => handleInputChange(part.index, e.target.value)}
                                          variant="outlined"
                                          size="small"
                                          InputProps={{
                                              style: {
                                                  height: '40px',
                                                  alignItems: 'center'
                                              }
                                          }}
                                          sx={{
                                              width: 'auto',
                                              minWidth: '100px',
                                              margin: '0 5px',
                                              verticalAlign: 'middle',
                                              borderColor: verificationStatus[part.index] === null ? 'default' : verificationStatus[part.index] ? 'green' : 'red'
                                          }}
                                          error={verificationStatus[part.index] === false}
                        />;
                    } else {
                        return <span key={'txt-' + idx}>{part.value}</span>;
                    }
                });
            }

            case "implicit":
                return <List>
                    <ListItem>{exercise.body}</ListItem>
                    <ListItem>
                        <TextField key='0'
                                   onChange={(e) => handleInputChange(0, e.target.value)}
                                   variant="outlined"
                                   size="small"
                                   InputProps={{
                                       style: {
                                           height: '40px',
                                           alignItems: 'center'
                                       }
                                   }}
                                   sx={{
                                       width: 'auto',
                                       minWidth: '400px',
                                       margin: '0 5px',
                                       verticalAlign: 'middle'
                                   }}/>
                    </ListItem>
                </List>
            default:
                return <List></List>
        }
    };

    const isVerificationDisabled = (): boolean => {
        return userInputs.length === 0 || verificationStatus.includes(null);
    }

    const getVerificationColor = (): 'default' | 'primary' | 'error' | 'success' => {
        if (verificationStatus.length === 0 || verificationStatus.includes(null)) {
            return 'primary';
        } else if (verificationStatus.includes(false)) {
            return 'error';
        } else {
            return 'success';
        }
    }

    const regenerate = () => {

    }

    return (
        <Card variant="outlined" sx={{margin: '20px', textAlign: 'left', position: 'relative'}}>
            <CardContent>
                <Typography variant="h5" component="h2" gutterBottom>
                    {exercise.title}
                </Typography>
                <Typography color="textSecondary" paragraph>
                    {exercise.description}
                </Typography>
                <Box>
                    {renderExercise(exercise)}
                </Box>
            </CardContent>

            <Box sx={{position: 'absolute', top: '10px', right: '10px'}}>
                <IconButton
                    onClick={regenerate}
                    disabled={true}
                    color="secondary"
                    aria-label="regenerate">
                    <AutorenewIcon/>
                </IconButton>
            </Box>
            <Box sx={{position: 'absolute', bottom: '10px', right: '10px'}}>
                <IconButton onClick={verifyAnswers}
                            disabled={isVerificationDisabled()}
                            color={getVerificationColor()}
                            aria-label="verify answer">
                    <CheckCircleIcon/>
                </IconButton>
            </Box>
        </Card>
    );

}

export default ExerciseView;
