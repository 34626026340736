import React from "react";
import AddExercises from "./AddExercises";
import {Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExerciseView from "./ExerciseView";
import {Lesson} from "@ancalled/grammarhub/dist/api";


interface LessonViewProps {
    lesson: Lesson;
}

const LessonView: React.FC<LessonViewProps> = (props) => {
    const {lesson} = props

    return (
        <Container>
            <Typography variant="h4" component="h1" gutterBottom align={'center'}>
                Lesson Overview
            </Typography>

            <Container>
                <AddExercises lessonId={lesson.id}/>
                {lesson.content.exercises.map(exercise => (
                    <ExerciseView key={exercise.id} exercise={exercise}/>
                ))}
            </Container>
        </Container>
    );
}

export default LessonView;