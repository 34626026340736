import ReactGA from 'react-ga';

const trackingId = process.env.REACT_APP_GA_TRACKING_ID;

export const initGA = () => {
    if (process.env.NODE_ENV === 'production' && trackingId) {
        console.log(`Tracking ga with trackingId: ${trackingId}`);
        ReactGA.initialize(trackingId);
    }
};

export const logPageView = () => {
    if (process.env.NODE_ENV === 'production' && trackingId) {
        console.log(`Tracking page ${window.location.pathname}`);
        ReactGA.set({page: window.location.pathname});
        ReactGA.pageview(window.location.pathname);
    }
};
